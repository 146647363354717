/*
*consumer_evection_queryOaInfo
*/
const __request = require(`./__request/__request_contentType_json`)
const consumer_evection_queryOaInfo = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/evection/queryOaInfo',
        data: data
    }
    return __request(pParameter)
}
export default consumer_evection_queryOaInfo