import Q from 'q';
import CostAttributionList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/cost-attribution-list/index'
import BelongToProjectList from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/belong-to-project-list/1.0.0/index.vue'
import consumer_admin_flight_reportConfiguration from '@/lib/data-service/haolv-default/consumer_admin_flight_reportConfiguration'
import consumer_evection_queryOaInfo from '@/lib/data-service/haolv-default/consumer_evection_queryOaInfo'
import consumer_t_tp_refund_change_policy_get from "@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get";

export default {
    props: {},
    data() {
        var validatePass = (rule, value, callback) => {
          if (this.projectName === '' && this.belongToSwitch) {
            callback(new Error('请选择归属项目'));
          } else {
            callback();
          }
        };
        return {
            oaNo: '',
            startDate: '',
            companyId: 2,
            userId: [],
            businessType: 1,
            OAOrderList: [],
            // 用于收集接口(shownName)返回需要隐藏的选项对应的中文名
            hideFieldNameBox: [],
            immutableSwitch: false,
            belongToSwitch: false,
            autocompleteDisabled: false,
            autocompleteChange: false,
            // 组件类型--回显、编辑
            typeBox: null,
            // 回显数据
            displayType: [],
            dataBox: null,
            loading: false,
            reportConfigurations: [],
            ruleForm: {
                name: '',
            },
            rules: {
                projectName: [
                    { required: true, validator: validatePass, trigger: 'change' }
                ],
            },
            // 归属项目相关
            centerCodeBox: '',
            projectName: '',
            projectCode: '',
            companyIdBox: 0,
            isFreeApprove: 1,

            testName: '',
        }
    },
    components: {CostAttributionList,BelongToProjectList},
    methods: {
        clickProject(){
            this.$refs.ruleForm.validateField('projectName');
        },
        handleInput() {
          this.immutableSwitch = false;
          this.centerCodeBox = '';
          if (this.autocompleteChange) {
            this.autocompleteChange = false;
            this.belongToSwitch = false;
            this.reportConfigurations.forEach((item) => {
              if (item.type != '5') {
                item.parameterValue = '';
                if (item.type == '3' || item.type == '4') {
                  item.deptName = '';
                  item.feeAffiliationType = null;
                }
              }
            });
            this.$emit('handleSelectDelete');
          }
          this.$refs.ruleForm.validate();
        },
        // 选择检索出来的OA单号信息，回填+只读其他选项
        handleSelect(value) {
            this.immutableSwitch = true;
            this.autocompleteChange = true;
            this.reportConfigurations.forEach((item,index) => {
                if (item.type != '5') {
                    value.reportConfigurations.forEach((Iitem,Iindex) => {
                        if (Iitem.parameter == item.parameter) {
                            item.parameterValue = Iitem.parameterValue;
                            if (item.type == '3' || item.type == '4') {
                                item.deptName = Iitem.parameterValue;
                                this.testName = Iitem.parameterValue;
                                item.parameterValue = Iitem.parameterId;
                                this.centerCodeBox = Iitem.parameterCode;
                                if (Iitem.parameterCode.indexOf('E') != -1) {
                                    this.belongToSwitch = true;
                                    this.$nextTick(() => {
                                        this.$refs.belongToProjectListRef.init({companyId: this.companyId});
                                    })
                                }
                                value.reportConfigurations.forEach((VItem,VIndex) => {
                                    if (VItem.parameter == 'attributionType') {
                                        item.feeAffiliationType = VItem.feeAffiliationType;
                                    }
                                });
                            }
                        }
                    });
                } else {
                    item.parameterValue = value.partnerOaNo;
                }
            });
            this.$emit('handleSelectCallback', {name: value.initialName,userId :value.initialUserId});
            this.$refs.ruleForm.validate();
        },
        // 检索OA单号信息
        async querySearch(queryString, cb) {
            if (queryString == '' || this.userId.length >= 2) {
                cb([]);
            } else if (this.userId.length < 2) {
                let dataBox = {
                  businessType: this.businessType,
                  callChannel: 1,
                  companyId: this.companyId,
                  oaNo: queryString,
                  startDate: '',
                  userId: this.userId ? `${this.userId[0]}` : '',
                };
                let res = await consumer_evection_queryOaInfo(dataBox);
                let restaurants = res.datas;
                restaurants.forEach((item,index) => {
                  item.value = item.oaDesc;
                  item.address = item.partnerOaNo;
                });
                this.OAOrderList = restaurants;
                cb(restaurants);
            }
        },
        getParameterList () {
            return this.hideFieldNameBox
        },
        getDataProject(){
            return {
              projectName: this.projectName,
              projectCode: this.projectCode,
            }
        },
        getData(){
            this.toSelectType();
            let data = {
                checkForm: null,
                list: []
            };
            let checkFormAll = [];
            let feeAffiliationTypeBox = null;

            this.reportConfigurations.forEach((item,index) => {
                checkFormAll.push(item.switchForm);
                if (item.parameter == "feeAffiliationId") {
                    feeAffiliationTypeBox = item.feeAffiliationType;
                }
                if (item.parameter == "attributionType") {
                    checkFormAll[index] = false;
                }
                data.list.push({
                    parameter: item.parameter,
                    parameterValue: item.parameterValue,
                    parameterForm: !item.switchForm,
                    parameterText: item.parameterText,
                });
            });
            data.list.forEach((item,index) => {
                if (item.parameter == "attributionType") {
                    item.parameterValue = feeAffiliationTypeBox == 1 ? 2 : 1;
                }
            });

            this.$refs.ruleForm.validateField('projectName');
            if (this.belongToSwitch && this.projectName == '') {
                if (data.checkForm) {
                    this.$message({
                        message: '请选择归属项目',
                        type: 'warning'
                    });
                }
                // 核验不通过
                data.checkForm = false;
            }


            if (checkFormAll.indexOf(true) == -1) {
                // 核验通过
                data.checkForm = true;
            } else {
                // 核验不通过
                data.checkForm = false;
            }
            data.centerCode = this.centerCodeBox;
            return data
        },
        changeSelect(){
            this.reportConfigurations.forEach((item,index) => {
                if (item.isMust == 0) {
                    item.switchForm = false;
                } else {
                    if (item.parameterValue == null || item.parameterValue == '' || item.parameterValue.length == 0 || item.parameterValue == -1) {
                        item.switchForm = true;
                    } else {
                        item.switchForm = false;
                    }
                }
            });
        },
        toSelectType(value){
            this.reportConfigurations.forEach((item,index) => {
                if (item.isMust == 0) {
                    item.switchForm = false;
                } else {
                    if (item.parameterValue == null || item.parameterValue == '' || item.parameterValue.length == 0 || item.parameterValue == -1) {
                        item.switchForm = true;
                    } else {
                        item.switchForm = false;
                    }
                    if (item.type == '3' || item.type == '4') {
                        if (this.centerCodeBox != null && this.centerCodeBox.indexOf('E') != -1) {
                            this.belongToSwitch = true;
                            this.$nextTick(() => {
                                this.$refs.belongToProjectListRef.init({companyId: this.companyId});
                            });
                        } else {
                            this.belongToSwitch = false;
                        }
                    }
                }
            });
        },
        validateNewUerCard(rule, value, callback) {
            this.reportConfigurations.forEach((item,index) => {
                if (item.isMust == 0) {
                    item.switchForm = false;
                } else {
                    if (item.parameterValue == null || item.parameterValue == '' || item.parameterValue.length == 0 || item.parameterValue == -1) {
                        item.switchForm = true;
                    } else {
                        item.switchForm = false;
                    }
                }
            });
            callback()
        },
        init_CostAttributionList(item){
            let params = {
                type: '002',
                showDept: item.type == 3,
            };
            if (this.$refs.CostAttributionList) {
                this.$refs.CostAttributionList.forEach((item,index) => {
                    item.init(params);
                })
            }
        },
        init (val) {
            let _this = this;
            _this.oaNo = val.oaNo;
            _this.startDate = val.startDate;
            _this.userId = val.userId;
            _this.businessType = val.businessType;
            _this.companyId = val.companyId;
            /*consumer_t_tp_refund_change_policy_get().then((Cres) => {
              // 0禁用
              _this.isFreeApprove = Cres.datas.isFreeApprove;
              if (_this.isFreeApprove == 0) {
                _this.immutableSwitch = true;
                _this.autocompleteDisabled = true;
              }
            });*/

            return new Promise(function (resolve, reject) {
                _this.loading = true;
                let mock_data = val.mock_data ? val.mock_data : null;
                let display_list = val.displayList ? val.displayList : [];
                let type = val.type ? val.type : null;

                let data = {
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: val.businessType,
                };
                let SwitchCostAttributionList = true;

                if (mock_data.enable) {
                    Q.when().then(() => {
                        let reportConfigurationsData = [
                            {
                                parameterText: '出差单号',
                                parameter: 'ChuChaiDanHao',
                                type:'1',
                                opetions: null,
                            },
                            {
                                parameterText: '业务类型',
                                parameter: 'YeWuLeiXing',
                                type:'2',
                                opetions: [
                                    {
                                        code: '0',
                                        text: '因公',
                                    },
                                    {
                                        code: '1',
                                        text: '因私',
                                    }
                                ],
                            },
                            {
                                parameterText: '成本中心',
                                parameter: 'ChengBenZhongXin',
                                type:'4',
                                opetions: null,
                            }
                        ];
                        reportConfigurationsData.forEach((item,index) => {
                            item.deptName = '';
                            item.parameterValue = '';
                            item.feeAffiliationType = 0;
                            item.switchForm = false;
                        });
                        return Q.resolve(reportConfigurationsData);
                    })
                        .then((reportConfigurationsData) => {
                            _this.reportConfigurations = reportConfigurationsData;
                            return Q.resolve();
                        })
                        .then(() => {
                            _this.reportConfigurations.forEach((item,index) => {
                                if (item.type == '3' || item.type == '4') {
                                    if (SwitchCostAttributionList) {
                                        _this.$nextTick(() => {
                                            _this.init_CostAttributionList(item);
                                        });
                                        SwitchCostAttributionList = false;
                                    }
                                }
                            });
                            _this.loading = false;
                        })
                } else {
                    consumer_admin_flight_reportConfiguration(data).then(res => {
                        Q.when().then(() => {
                            _this.hideFieldNameBox = [];
                            res.datas.reportConfigurations.sort((a,b) => {
                                return (a.serial || 2) - (b.serial || 2);
                            });
                            res.datas.reportConfigurations.forEach((item,index) => {
                                item.isMust = item.isMust != 0 && item.isMust != 1 ? 1 : item.isMust;
                                item.deptName = '';
                                item.parameterValue = '';
                                item.feeAffiliationType = 0;
                                item.switchForm = false;
                                // 成本中心需回显一个默认的
                                if (item.parameter === 'feeAffiliationId') {
                                    item.deptName = '各自所在的部门';
                                    item.parameterValue = -1;
                                    item.feeAffiliationType = 1;
                                }
                                if (item.parameter === 'attributionType') {
                                    item.parameterValue = 1;
                                    item.feeAffiliationType = 1;
                                }
                                if (item.isNotShown == 1) {
                                    _this.hideFieldNameBox.push(item.shownName);
                                }
                            });
                            return Q.resolve();
                        })
                            .then(() => {
                                _this.reportConfigurations = res.datas.reportConfigurations;
                                let dataBoxOne = null;
                                if (type == 'immutable') {
                                    _this.immutableSwitch = true;
                                    _this.autocompleteDisabled = true;
                                }
                                if (type == 'display' || type == 'immutable') {
                                    _this.reportConfigurations.forEach((RItem,TIndex) => {
                                        display_list.forEach((DItem,DIndex) => {
                                            if (RItem.parameter == DItem.parameter) {
                                                RItem.parameterValue = DItem.parameterValue;
                                                if (DItem.parameter == "attributionType") {
                                                    dataBoxOne = DItem.parameterValue
                                                }
                                            }
                                        });
                                    });
                                    _this.reportConfigurations.forEach((item,index) => {
                                        if (item.parameter == "feeAffiliationId") {
                                            item.feeAffiliationType = dataBoxOne == 1 ? 2 : 1;
                                        }
                                    });
                                }
                                _this.loading = false;
                                return Q.resolve();
                            })
                            .then(() => {
                                _this.reportConfigurations.forEach((item,index) => {
                                    if (item.type == '3' || item.type == '4') {
                                        if (SwitchCostAttributionList) {
                                            _this.$nextTick(() => {
                                                _this.init_CostAttributionList(item);
                                            });
                                            SwitchCostAttributionList = false;
                                        }
                                    }
                                });
                                if (_this.oaNo && _this.startDate && _this.userId && _this.userId.length == 1) {
                                    let dataBox = {
                                        businessType: _this.businessType,
                                        callChannel: 1,
                                        companyId: _this.companyId,
                                        oaNo: _this.oaNo,
                                        startDate: _this.startDate,
                                        userId: _this.userId[0],
                                    };
                                    consumer_evection_queryOaInfo(dataBox).then((res) => {
                                        if (res.datas.length != 0) {
                                            _this.handleSelect(res.datas[0]);
                                            _this.loading = false;
                                            resolve(_this.hideFieldNameBox);
                                        }
                                    });
                                } else {
                                    _this.loading = false;
                                    resolve(_this.hideFieldNameBox);
                                }
                            })
                    });
                }
            });
        }
    },
    watch: {},
    created() {

    },
    mounted() {

    },
    activated() {

    },
    deactivated() {

    },
}
